<template>
  <div id="loan-detail" class="same-h">
    <div ref="topElement"></div>
    <div v-if="!showOffer" class="vx-row">
      <div class="vx-class w-full pr-4 pl-4">
        <vx-card class="mt-2 mb-2">
          <div class="vx-row">
            <div class="vx-col w-full mb-4">
              <h1 class="big-title-bg extrabold mb-2 text-5xl">{{ getTitle }}</h1>
              <vs-progress class="mb-2" :percent="calculatePercentageSteps()" :height="10" color="primary"></vs-progress>
            </div>
            <!-- COMPONENTES QUE ROTAN -->
            <div v-if="isMounted" class="vx-col w-full">
              <SyntagePage v-if="currentStep == 'pfae_0_sat_link'" :onboardingStepData="base" @updated="getProjectData" />
              <ApplicantAddress v-if="currentStep == null" :project="base" @updated="getProjectData"></ApplicantAddress>
              <ProjectFGLocationAddress v-if="currentStep == 'pf_1_applicant_address' && isEcotechnologiesLoan && !applicantAddressSameAsLocationAddress" :project="base" @updated="getProjectData"></ProjectFGLocationAddress>
              <GeneralData v-if="(currentStep == 'pf_1_applicant_address' && !isEcotechnologiesLoan && !applicantAddressSameAsLocationAddress) || currentStep == 'pf_2_tech_address' || (currentStep == 'pf_1_applicant_address' && isEcotechnologiesLoan && applicantAddressSameAsLocationAddress)" :project="base" @updated="getProjectData"></GeneralData>
              <PersonalOccupationInformation v-if="currentStep == 'pf_3_loan_profile'" :project="base" @updated="getProjectData"></PersonalOccupationInformation>
              <PersonalEmploymentInformation v-if="currentStep == 'pf_4_occupation'" :project="base" @updated="getProjectData"></PersonalEmploymentInformation>
              <PersonalIncomeSourceInformation v-if="currentStep == 'pf_5_job_profile'" :project="base" @updated="getProjectData"></PersonalIncomeSourceInformation>
              <CreditScoreRequest v-if="currentStep == 'pf_7_expenses'" :project="base" @updated="getProjectData"></CreditScoreRequest>
              <GuaranteeData v-if="(currentStep == 'pf_8_loan_checkpoint' || currentStep == 'pfae_5_credit_acceptance') && guaranteeRequired && !is_guarantee_personal_completed"  :project="base" @updated="getProjectData" @saved="updateGuaranteeSaved"></GuaranteeData>
              <!-- <LoanOffer v-if="currentStep == 'pf_9_references'" :onboardingStepData="base" :isMoral="false" @updated="customizeLoan" :isCustomizing="isCustomizing"></LoanOffer> -->
              <DataChecking v-if="currentStep == 'pf_10_credit_acceptance'" :project="base" @updated="getProjectData"></DataChecking>
              <!-- <GuaranteeAddressData v-if="currentStep == 'pf_10_credit_acceptance' && guaranteeRequired && is_guarantee_personal_completed && !is_guarantee_address_completed" :project="base" @updated="getProjectData" @saved="updateGuaranteeSaved"></GuaranteeAddressData> -->
              <!-- <GuaranteeCreditScoreRequest v-if="currentStep == 'pf_10_credit_acceptance' && guaranteeRequired && is_guarantee_personal_completed && is_guarantee_address_completed" :project="base" @updated="getProjectData" @saved="updateGuaranteeSaved"></GuaranteeCreditScoreRequest> -->
              <ApplicantDocumentsControl ref="ApplicantDocumentsController" v-if="currentStep == 'pf_11_guarantee_data'" :project="base" @updated="getProjectData" @saved="getProjectDataLite"></ApplicantDocumentsControl>
              <ContractsPage v-if="currentStep == 'pf_12_documents_and_identity'" :onboardingStepData="base" @updated="getProjectData" :isMoral="false"></ContractsPage>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <div v-else class="">
      <LoanOffer v-if="isMounted" @updated="getProjectData" :onboardingStepData="base" :isMoral="isMoral" />
    </div>
  </div>
</template>
  
<script>
import ApplicantAddress from '../components/ApplicantAddress.vue';
import CreditScoreRequest from '../components/CreditScoreRequest.vue';
import DataChecking from '../components/DataChecking.vue';
import GeneralData from '../components/GeneralData.vue';
import PersonalEmploymentInformation from '../components/PersonalEmploymentInformation.vue';
import PersonalIncomeSourceInformation from '../components/PersonalIncomeSourceInformation.vue';
import PersonalOccupationInformation from '../components/PersonalOccupationInformation.vue';
import ProjectFGLocationAddress from '../components/ProjectFGLocationAddress.vue';
import LoanOffer from '../components/shared_components/LoanOffer.vue';
import GuaranteeData from "../components/guarantee/GuaranteeData.vue";
import GuaranteeAddressData from "../components/guarantee/GuaranteeAddressData.vue";
import GuaranteeCreditScoreRequest from "../components/guarantee/GuaranteeCreditScoreRequest.vue";
import ApplicantDocumentsControl from '../components/ApplicantDocumentsControl.vue';
import ContractsPage from '../components/shared_components/ContractsPage.vue';
import SyntagePage from '../components/shared_components/SyntagePage.vue';

const requiredObjects = [

  'finance.guarantee',
  'finance.guarantee.guaranteeProfile',
  'finance.guarantee.guaranteeProfile.personal',
  'finance.guarantee.guaranteeProfile.personal.creditProfile',
  'finance.guarantee.guaranteeProfile.personal.identityVerifications',
  'finance.guarantee.guaranteeProfile.personal.phone',
  'finance.guarantee.guaranteeProfile.personal.address',

  'finance.owner.guaranteeProfile',
  'finance.owner.guaranteeProfile.personal',
  'finance.owner.guaranteeProfile.business',

  'client.user.business.ownerBusinessProfile',
  'client.user.business.ownerBusinessProfile.address',
  'client.user.business.ownerBusinessProfile.creditProfile',
  'client.user.business.ownerBusinessProfile.personal',
  'client.user.business.ownerBusinessProfile.phone',
  'client.user.business.ownerPersonalProfile',
  'client.user.business.ownerPersonalProfile.address',
  'client.user.business.ownerPersonalProfile.creditProfile',
  'client.user.business.ownerPersonalProfile.phone',

  'client.kycProfile',

  'client.user.personal.address',
  'client.user.personal.creditProfile',
  'client.user.personal.identityVerifications',
  'client.user.personal',
  'client.user.personal.bankAccounts',
  'client.user.personal.phone',
  'client.user.personal.PPIncomeSources.phone',
  'client.user.personal.PPIncomeSources.incomeSourcesProofs',

  'client.user.business.address',
  'client.user.business.personal.address',
  'client.user.business.personal.creditProfile',
  'client.user.business.personal',
  'client.user.business.personal.phone',
  'client.user.business',
  'client.user.business.bankAccounts',
  'client.user.business.phone',

  'contracts',
  'supplier',
  'supplier.contacts',
  'projectFgLocations',
  'agent',
  'agent.user',
  'agent.phone',
  'amortizations'
];

export default {
  name: "LoanDetail",
  props: ['id'],
  data() {
    return {
      loading: false,
      isMounted: false,
      base: {},
      step: 1,
      isCustomizing: false,
      auxStep: null,
      guaranteeFlags: [false, false, false],
    }
  },
  components: {
    ApplicantAddress,
    GeneralData,
    PersonalEmploymentInformation,
    PersonalIncomeSourceInformation,
    CreditScoreRequest,
    DataChecking,
    PersonalOccupationInformation,
    ProjectFGLocationAddress,
    LoanOffer,
    GuaranteeData,
    GuaranteeAddressData,
    GuaranteeCreditScoreRequest,
    ApplicantDocumentsControl,
    ContractsPage,
    SyntagePage
  },
  computed: {
    currentStep() {
      return this.base.loan_request_step;
    },
    isMoral() {
      return this.UserPersonType == 0 || this.UserPersonType == 3;
    },
    isEcotechnologiesLoan() {
      return this.base.finance?.loan_type == 1 && this.technologySelected?.family_group?.location_required == 1;
    },
    technologySelected() {
      if(this.isMounted) {
        return this.base.fg_locations.length > 0 ? this.base.fg_locations[0] : null;
      } else {
        return {};
      }
    },
    guaranteeRequired() {
      return this.base.finance.guarantee_required == 1;
    },
    is_guarantee_personal_completed() {
      if(this.guaranteeRequired) {
        let personal = this.base.finance.guarantee.guarantee_profile.personal;
        return personal.curp != null && personal.rfc != null && personal.phone_id != null && personal.first_name != null && personal.last_name_1 != null && this.guaranteeFlags[0];
      } else {
        return null;

      }
    },
    is_guarantee_address_completed() {
      if(this.guaranteeRequired) {
        let address = this.base.finance.guarantee.guarantee_profile.personal.address;
        return address.neighborhood_id != null && this.guaranteeFlags[1];
      } else {
        return null;
      }
    },
    finance() {
      return this.base.finance;
    },
    hasOffer() {
      if (this.isMounted) {
        return this.base.loan_proposal_timestamp != null && this.base.finance.credit_acceptance_date == null;
      } else {
        return false;
      }
    },
    loanOfferIsAccepted() {
      if (this.isMounted) {
        return this.finance.credit_acceptance_date != null;
      } else {
        return false;
      }
    },
    showOffer() {
      if (this.loanOfferIsAccepted) {
        return false;
      } else if (this.hasOffer) {
        return true;
      } else {
        return false;
      }
    },
    address() {
        if(this.isMoral) {
            return this.base.client.user.business.address;
        } else {
            return this.base.client.user.personal.address;
        }
    },
    applicantAddressSameAsLocationAddress() {
      return this.address.id == this.technologySelected?.location.address_id;
    },
    getTitle()
    {
      let title = "Solicitud de crédito";

      switch (this.finance.financial_product_id) {
        case 1:
          title = "Crédito Solar";
          break;
        case 2:
          title = "Crédito Automotriz";
          break;
        case 3:
          title = "Crédito PYME";
          break;
        case 4:
          title = "Crédito Personal";
          break;
      
        default:
          break;
      }

      return title;
    }
  },
  watch: {

  },
  async beforeMount() {
    await this.getProjectData();
    // await this.loadSavedProjectFgLocations();
  },
  methods: {
    async getProjectData() {
      try {
        this.showLoading(true);
        this.isMounted = false;
        let params = "with[]=" + requiredObjects.join("&with[]=");
        let response = await axios.get(`/api/v2/projects/${this.id}?${params}`);
        this.base = response.data;

        await this.loadSavedProjectFgLocations();
        this.auxStep = this.currentStep;
        this.isMounted = true;
        this.gottop();
        this.showLoading(false);
      }
      catch (e) {
        console.log(e);
        if (e.response.status === 403) {
          this.$router.replace('/solicitante/inicio');
        }
      }
    },
    async getProjectDataLite() {
      try {
        this.showLoading(true);
        let params = "with[]=" + requiredObjects.join("&with[]=");
        let response = await axios.get(`/api/v2/projects/${this.id}?${params}`);
        this.base = response.data;
        await this.loadSavedProjectFgLocations();
        this.showLoading(false);
      }
      catch (e) {
        console.log(e);
        if (e.response.status === 403) {
          this.$router.replace('/solicitante/inicio');
        }
      }
    },
    calculateSteps() {
      const steps = {
        'pf_1_applicant_address': 1,
        'pf_2_tech_address': 2,
        'pf_3_loan_profile': 3,
        'pf_4_occupation': 4,
        'pf_5_job_profile': 5,
        'pf_6_income': 6,
        'pf_7_expenses': 7,
        'pf_8_loan_checkpoint': 8,
        'pf_9_references' : 9,
        'pf_10_credit_acceptance' : 10,
        'pf_11_guarantee_data' : 11,
        'pf_12_documents_and_identity' : 12,
        'pf_13_contracts_sign' : 13,
      };

      return steps[this.auxStep] || 1;
    },
    calculatePercentageSteps(){
      const steps = {
        'pf_1_applicant_address': 7.5,
        'pf_2_tech_address': 15,
        'pf_3_loan_profile': 22.5,
        'pf_4_occupation': 30,
        'pf_5_job_profile': 37.5,
        'pf_6_income': 45,
        'pf_7_expenses': 52.5,
        'pf_8_loan_checkpoint': 60,
        'pf_9_references' : 67.5,
        'pf_10_credit_acceptance' : 75,
        'pf_11_guarantee_data' : 82.5,
        'pf_12_documents_and_identity' : 90,
        'pf_13_contracts_sign' : 100,
      };

      return steps[this.auxStep] || 10;
    },
    async loadSavedProjectFgLocations(){
      try {
        // this.showLoading(true);
        const res = await axios.get(`/api/loan-onboarding/get/${this.base.id}/getProjectFgLocations`);
        this.base.fg_locations = res.data;
        // this.showLoading(false);
      }
      catch (e) {
        console.log(e);
      }
    },
    customizeLoan() {
      if(this.isCustomizing) {
        this.getProjectData();
      } else {
        this.isCustomizing = true;
      }
    },
    updateGuaranteeSaved(val) {
      this.guaranteeFlags[val] = true;
    },
    gottop() {
      try {
        this.$refs.topElement?.scrollIntoView({ behavior: 'smooth' });
      } catch (e) {
        console.log(e);
      }
    },
  }
}
</script>