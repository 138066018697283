<template>
    <div id="loan-offer">
        <div v-if="menuOption == 0">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <h3 class="mb-1">Documentos requeridos.</h3>
                    <p>Podrás firmar el contrato una vez que validemos la información.</p>
                    <vs-divider></vs-divider>
                </div>
            </div>
            <div class="vx-row mb-4 m-1">
                <div @click.stop="requestMatiPopUp(onboardingStepData)" class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green clickable-img cursor-pointer">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>INE</h5>
                            <p class="mb-2">Verificación de identidad</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getIdentityData().color">{{ getIdentityData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showSyntageStep" class="vx-row mb-4 m-1">
                <div @click.stop="menuOption = 4" class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green clickable-img cursor-pointer">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Vincula tu cuenta del SAT (CIEC)</h5>
                            <p class="mb-2">Validaremos tu información de forma segura y confidencial.</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getSyntageData().color">{{ getSyntageData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-4 m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green clickable-img cursor-pointer" @click.stop="menuOption = 1">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Comprobante de domicilio</h5>
                            <p class="mb-2">No mayor a 3 meses, legible, sin cortes</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getAddressData().color">{{ getAddressData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-4 m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green cursor-pointer" @click.stop="menuOption = 3">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Comprobante de ingreso</h5>
                            <p class="mb-2">Recibos de nomina o estados de cuenta a tu nombre.</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getProofsData().color">{{ getProofsData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-base m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green cursor-pointer" @click.stop="menuOption = 2">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Cuenta CLABE</h5>
                            <p class="mb-2">A tu nombre o de la empresa. Aquí se depositará el crédito.</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getBankAccountData().color">{{ getBankAccountData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-1/2 md:w-1/12">
                    <vs-button size="sm" color="black" type="border" @click.stop="goBack()">Atrás</vs-button>
                </div>
                <div class="vx-col sm:w-1/2 md:w-2/12">
                    <!-- <vs-button class="w-full" size="sm" color="black" @click.stop="goContinue()">Continuar</vs-button> -->
                </div>
            </div>
        </div>
        <div v-if="menuOption == 1">
            <ApplicantAddressDocument
                :onboardingStepData="onboardingStepData"
                :document="document"
                @on-back="onBack"
                @on-continue="onContinue"
                @setAddressDocument="setAddressDocument"
                @updated="getProjectData"
            />
        </div>
        <div v-if="menuOption == 2">
            <ApplicantBusinessCLABE
                :onboardingStepData="onboardingStepData"
                :isPersonal="true"
                @updated="setBankAccount"
                @on-back="onBack"
                @on-continue="onContinue"
                @set-bank-account="setBankAccount" />
        </div>
        <div v-if="menuOption == 3">
            <IncomeSourceProofsDocument
                :incomeSources="onboardingStepData.client.user.personal.p_p_income_sources"
                @on-back="onBack" />
        </div>
        <div v-if="menuOption == 4">
            <SyntagePage
                :onboardingStepData="onboardingStepData"
                :isFromDocuments="true"
                @on-back="onBack"
                @on-continue="onContinue"
                @updated="getProjectData" />
        </div>

        
        <mati-popup
            v-if="isMatiReady"
            :user-id-to-activate="matiData.userId" 
            @on-mati-process-started="onMatiProcessStarted"
            @finished="onMatiProcessFinished"
            />

    </div>
</template>

<script>
import ApplicantAddressDocument from './ApplicantAddressDocument.vue';
import ApplicantBusinessCLABE from './business/ApplicantBusinessCLABE.vue';
import MatiPopup from "@components/mati/MatiPopup";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import IncomeSourceProofsDocument from './shared_components/IncomeSourceProofsDocument.vue';
import SyntagePage from "../components/shared_components/SyntagePage.vue"

const DOCUMENT_REQUIRED_ID = 32;
const PM_CROWDFUNDING_LOAN_TYPE = 2;    

export default {
    name: "ApplicantPersonalDocuments",
    props: ["onboardingStepData", "isMoral"],
    mixins: [userIdentityHelper],
    components: {
        ApplicantAddressDocument,
        ApplicantBusinessCLABE,
        MatiPopup,
        IncomeSourceProofsDocument,
        SyntagePage
    },
    data: () => ({
       menuOption: 0,
       isMounted: false,
       document: null,
       hasBankAccount: false,
       matiData: {
            userId: null,
            personalProfileId: null
        },
        identityIsCompleted: false,
    }),
    async created() {
        this.isMounted = false;
        this.showLoading(true, "Recopilando información...")
        await this.getDocument();
        this.showLoading(false)
        this.isMounted = true;
    },
    computed: {
        apiStorageBasePrefix(){
            return this.ApiDomain + "/storagev3/documents/";
        },
        hasAlreadyDocument() {
            return this.document && this.document.id;
        },
        hasAlreadyBankAccount() {
            return this.hasBankAccount || this.onboardingStepData.client.user.personal.bank_accounts.length > 0;
        },
        hasIdentity() {
            return this.onboardingStepData.client.user.personal.identity_verifications.length > 0;
        },
        hasIncomeSources() {
            return this.onboardingStepData.client.user.personal.p_p_income_sources.length > 0;
        },
        hasIncomeSourceProofs() {
            if(this.hasIncomeSources) {
                return this.onboardingStepData.client.user.personal.p_p_income_sources[0].income_sources_proofs.length > 0;
            }
            return false;
        },
        isMatiReady() {
            return this.onboardingStepData != null;
        },
        client()
        {
            return this.onboardingStepData.client;
        },
        user_copy() {
            return this.client.user;
        },
        personal() {
            return this.user_copy.personal;
        },
        showSyntageStep() {
            if(this.onboardingStepData.finance.crowdfunding_loan_type == PM_CROWDFUNDING_LOAN_TYPE) {
                return this.personal.last_sat_profile != null ? (this.personal.last_sat_profile.credential_status == "invalid" || this.personal.last_sat_profile.credential_status == "error") : true;
            } else {
                return false;
            }
        }
    },
    methods: {
        async getProjectData() {
            await this.$emit('updated', 1);
        },
        goBack() {
            this.$emit('on-back');
        },
        goContinue() {
            this.$emit('on-continue');
        },
        backFromStakeholders() {
            this.menuOption = 0;
        },
        async getDocument() {
            try {
                const response = await axios.get(`${this.apiStorageBasePrefix}get/document-data/${DOCUMENT_REQUIRED_ID}/${this.UserId}`);
                this.document = response.data;
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        async onBack() {
            this.menuOption = 0;
        },
        async onContinue() {
            this.showLoading(true, "Guardando información...");
            await this.getDocument();
            this.menuOption = 0;
            this.showLoading(false);
        },
        async requestMatiPopUp(profile) {
            await this.clearMatiPoUpSettings();
            this.matiData.userId = profile.client.user.id;
            this.openMatiPopup();
        },
        async getPersonalIdentityInfo(personalId) {
            this.status = await this.getUserIdentityStatus(personalId);
            this.identityIsCompleted = this.verificationIsCompleted(personalId);
        },
        async onMatiProcessStarted() {
              this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
              this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", false);
              this.processWasStarted = true;
          },
        async openMatiPopup() {
            this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
            this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", true);
        },
        onMatiProcessFinished() {
            this.onboardingStepData.client.user.personal.identity_verifications[0] = { identity_status : 0}
        },
        validateIdentity(project) {
            let profile = project.client.user.personal;
            const identityData = profile.identity_verifications;
            return identityData.length > 0;
        },
        clearMatiPoUpSettings() {
            this.matiData = {
                userId: null
            }
        },
        setBankAccount(e) {
            this.menuOption = 0;
            this.onboardingStepData.client.user.personal.bank_accounts[0] = e;
            this.hasBankAccount = true;
        },
        hasError(val) {
            return this.errors.has(val);
        },  
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },

        setAddressDocument(document) {
            this.document = document
        },

        // validaciones generales

        getBankAccountData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };
            if(this.hasAlreadyBankAccount) {
                const bankAccount = this.onboardingStepData.client.user.personal.bank_accounts[0];
                if(bankAccount.verified == "verified") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(bankAccount.verified == "unverified") {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(bankAccount.verified == "verified_requested") {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(bankAccount.verified == "rejected") {
                    data.status = "Rechazada";
                    data.color = "warning";
                } else if(bankAccount.verified == "cep_requested") {
                    data.status = "Cargado";
                    data.color = "success";
                } 
            }

            return data;
        },
        getAddressData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.hasAlreadyDocument) {
                if(this.document.is_verified == "verificado") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(this.document.is_verified == "rechazado") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(this.document.is_verified == "sin verificar") {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;
        
        },
        getIdentityData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.hasIdentity) {
                const identity = this.onboardingStepData.client.user.personal.identity_verifications[0];
                if(identity.identity_status == 1) {
                    data.status = "Completado";
                    data.color = "success";
                } else if(identity.identity_status == 0) {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(identity.identity_status == 2) {
                    data.status = "En revisión";
                    data.color = "success";
                } else if(identity.identity_status == 4) {
                    data.status = "Rechazada";
                    data.color = "warning";
                } else {
                    data.status = "En revisión";
                    data.color = "warning";
                }
            }

            return data;
        },
        getProofsData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.hasIncomeSourceProofs) {
                const proofs = this.onboardingStepData.client.user.personal.p_p_income_sources[0].income_sources_proofs;
                let isVerified = [];
                let isPending = [];
                let isRejected = [];
                let documentMissing = [];
                for (let index = 0; index < proofs.length; index++) {
                    const proof = proofs[index];
                    const document = proof.document_file;

                    if(document) {
                        if(document.is_verified == "verificado") {
                            isVerified.push(document);
                        } else if(document.is_verified == "rechazado") {
                            isRejected.push(document);
                        } else if(document.is_verified == "sin verificar") {
                            isPending.push(document);
                        }
                    } else {
                        documentMissing.push(proof);
                    }
                }

                if(isVerified.length > 2) {
                    data.status = "Completado";
                    data.color = "success";
                } else if(isRejected.length > 2) {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(isPending.length > 1) {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(documentMissing.length > 0) {
                    data.status = "Pendiente";
                    data.color = "warning";

                }
            }


            return data;
        },
        getSyntageData()
        {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            const satProfile = this.personal.last_sat_profile;
            if(satProfile != null) {
                if(satProfile.credential_status == "valid") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(satProfile.credential_status == "invalid" || satProfile.credential_status == "error") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(satProfile.credential_status == "pending") {
                    data.status = "En revisión";
                    data.color = "warning";
                }
            }

            return data;
        }
    }
}
</script>
<style>

.border-bottom-green {
    border-bottom: 1px solid #A9E3A4;
    padding-bottom: 1rem;
}

</style>