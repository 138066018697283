<template>
    <div id="loan-offer" class="mt-base">
        <div v-if="hasDocumentationCompletedDate && repLegalDataIsCompleted" class="vx-row mb-4">
            <div class="vx-col sm:w-full md:w-5/12 mb-4">
                <h3 class="mb-base">Tu solicitud está en revisión.</h3>
                <p class="mb-2 black">
                    <span class="bold text-primary">¡Listo! Ya tenemos todo.</span> <br>
                    Estamos analizando tu información para aprobar el crédito, esto puede tomar algunas horas, te notificaremos por correo.
                </p>
            </div>
            <div class="vx-col sm:w-full md:w-1/2 mb-4">
                <img class="sm:w-1/5 md:w-1/5":src="getIcon('04')" alt="Completando solicitud">
            </div>
        </div>
        <div v-if="hasDocumentationCompletedDate && !repLegalDataIsCompleted" class="vx-row mb-4">
            <div class="vx-col sm:w-full md:w-5/12 mb-4">
                <h3 class="mb-base">Tu solicitud está en revisión.</h3>
                <p class="mb-2 black">
                    <span class="bold text-primary">¡Gracias! Estamos analizando tu información.</span> <br>
                    Los accionistas que serán integrados como obligados 
                    <br> solidarios recibirán un correo muy pronto con instrucciones 
                    <br> para continuar el proceso.
                </p>
            </div>
            <div class="vx-col sm:w-full md:w-1/2 mb-4">
                <img class="sm:w-1/5 md:w-1/5":src="getIcon('04')" alt="Completando solicitud">
            </div>
        </div>
        <div v-if="menuOption == 0 && isMounted">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <h2>Documentos requeridos</h2>
                    <p>Podrás firmar el contrato una vez que validemos la información.</p>
                    <vs-divider></vs-divider>
                </div>
            </div>
            <div class="vx-row mb-4 m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green clickable-img cursor-pointer" @click.stop="menuOption = 1">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Información de accionistas y representante legal.</h5>
                            <p class="mb-2">Identificación oficial y comprobante de domicilio</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="stakeholdersDataIsCompletedData().color">{{ stakeholdersDataIsCompletedData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-4 m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green cursor-pointer" @click.stop="menuOption = 2">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Información de la empresa</h5>
                            <p class="mb-2">Documentos que la constituyen</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="businessData().color">{{ businessData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- INFORMACIÓN DE LA UBICACIÓN -->
            <div v-if="isEcotechnologiesLoan" class="vx-row mb-base m-1">
                <div class="vx-col sm:w-2/3 md:w-5/12 border-bottom-green clickable-img cursor-pointer" @click.stop="menuOption = 3">
                    <div class="flex justify-between mb-3 p-0 mt-4">
                        <div class="mr-4">
                            <h5>Información de la ubicación de instalación</h5>
                            <p class="mb-2">Documento de propiedad</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getLocationData().color">{{ getLocationData().status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-1/2 md:w-2/12">
                    <vs-button v-if="isEcotechnologiesLoan" :disabled="!businessDataIsCompleted || !repLegalDataIsCompleted || !locationIsReady" class="w-full" size="sm" color="black" @click.stop="goContinue()">Continuar</vs-button>
                    <vs-button v-else :disabled="!businessDataIsCompleted || !repLegalDataIsCompleted" class="w-full" size="sm" color="black" @click.stop="goContinue()">Continuar</vs-button>
                </div>
            </div>
        </div>
        <div v-if="menuOption == 1">
            <ApplicantStakeholdersDocuments 
                ref="ApplicantStakeholders"
                :onboardingStepData="onboardingStepData" 
                @updated="getProjectData"
                @on-back="backFromStakeholders"/>
        </div>
        <div v-if="menuOption == 2">
            <ApplicantBusinessDocuments 
                :onboardingStepData="onboardingStepData" 
                :isMoral="isMoral" 
                @on-back="backFromStakeholders"
                @updated="getProjectData"/>
        </div>
        <div v-if="menuOption == 3">
            <LocationDocument 
                :fgLocation="fgLocation" 
                :projectId="onboardingStepData.id" 
                @on-back="goBack" 
                :document="projectDocument"/>
        </div>
    </div>
</template>

<script>
import ApplicantStakeholdersDocuments from './ApplicantStakeholdersDocuments.vue';
import ApplicantBusinessDocuments from './ApplicantBusinessDocuments.vue';
import LocationDocument from '../shared_components/LocationDocument.vue';

const DOCUMENT_REQUIRED_ID = 25;
const ADDRESS_DOCUMENT_REQUIRED_ID = 33;
const REP_LEGAL_ADDRESS_PROOF_ID = 31;

const PM_PROOF_ID_1 = 183;
const PM_PROOF_ID_2 = 184;
const PM_PROOF_ID_3 = 185;
const DOCUMENT_PROJECT_REQUIRED_ID = 48;

export default {
    name: "ApplicantDocuments",
    props: ["onboardingStepData", "isMoral"],
    components: {
        ApplicantStakeholdersDocuments,
        ApplicantBusinessDocuments,
        LocationDocument
    },
    data: () => ({
       menuOption: 0,
       document: null,
       projectDocument: null,
       repLegalAddressProof: null,
       isMounted: false,
       addressCompleted: false,
       financialStatements: [],
       hasFinancialStatements: false
    }),
    async created() {
        this.isMounted = false;
        await this.getDocument();
        this.repLegalAddressProof = await this.getDocument(REP_LEGAL_ADDRESS_PROOF_ID, true);
        this.addressCompleted = await this.stakeholdersDataIsCompletedData();
        // this.financialStatements[0] = await this.getProjectDocument(PM_PROOF_ID_1, true);
        // this.financialStatements[1] = await this.getProjectDocument(PM_PROOF_ID_2, true);
        // this.financialStatements[2] = await this.getProjectDocument(PM_PROOF_ID_3, true);
        this.projectDocument = await this.getProjectDocument(DOCUMENT_PROJECT_REQUIRED_ID, true);
        this.isMounted = true;
    },
    computed: {
        base () {
            return this.onboardingStepData;
        },
        apiStorageBasePrefix(){
            return this.ApiDomain + "/storagev3/documents/";
        },
        bankAccount() {
            return this.onboardingStepData.client.user.business.bank_accounts.length > 0;
        },
        hasDocument() {
            return this.document != null;
        },
        businessDataIsCompleted() {
            return this.bankAccount && this.hasDocument;
        },
        repLegalDataIsCompleted() {
            return this.stakeholdersDataIsCompletedData().status == "Completado";
        },
        locationIsReady() {
            return this.getLocationData().status != "Rechazado";
        },
        // repLegalAddressData() {
        //     return this.onboardingStepData.client.user.business.personal.address.neighborhood_id != null;
        // },
        isEcotechnologiesLoan() {
            if(this.isMounted) {
                return this.onboardingStepData.finance?.loan_type == 1 && this.technologySelected?.family_group?.location_required == 1;
            } else {
                return false;
            }
        },
        technologySelected() {
            if(this.isMounted) {
                return this.onboardingStepData.fg_locations.length > 0 ? this.onboardingStepData.fg_locations[0] : null;
            } else {
                return {};
            }
        },
        fgLocation() {
            return this.onboardingStepData.project_fg_locations ? this.onboardingStepData.project_fg_locations[0] : null;
        },
        hasLocationDocument() {
            if(this.isEcotechnologiesLoan) {
                return this.projectDocument != null;
            } else {
                return true;
            }
        },
        hasDocumentationCompletedDate() {
            return this.onboardingStepData.documentation_complete_date != null;
        }
    },
    methods: {
        async goBack() {
            this.menuOption = 0;
            await this.$emit('saved', 1);
            // this.$emit('updated');
        },
        async getProjectData() {
            this.isEdit = false;
            await this.$emit('saved', 1);
        },
        async getDocument(docId = DOCUMENT_REQUIRED_ID, returnData = false) {
            try {
                const response = await axios.get(`${this.apiStorageBasePrefix}get/document-data/${docId}/${this.UserId}`);
                if(returnData) {
                    return response.data;
                }
                this.document = response.data;
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        async getProjectDocument(docId = DOCUMENT_REQUIRED_ID, returnData = false) {
            try {
                const response = await axios.get(`${this.apiStorageBasePrefix}get/project-document-data/${docId}/${this.onboardingStepData.id}`);
                if(returnData) {
                    return response.data;
                }
                this.document = response.data;
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        stakeholdersDataIsCompleted() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            const guarantees = this.base.finance.guarantees_list;
            const flags = [];


            if(guarantees.length == 0) {
                data.status = "Pendiente";
                data.color = "warning";
                return data;

            }

            guarantees.forEach(guarantee => {
                if(guarantee.is_guarantee == 1) {
                    if(guarantee.guarantee_profile.rejected_date == null) {
                        // Evaluamos si el guarantee ya está completo.
                        if(guarantee.guarantee_profile.guarantee_profile_step == "os_pf_identity_verification"
                            || guarantee.guarantee_profile.guarantee_profile_step == "os_pm_rep_legal_identity_verification"
                        ) {
                            flags.push(true);
                        } else {
                            flags.push(false);
                        }
                    } else {
                        flags.push(false);
                    }
                }
            });

            if(flags.every(f => f == true)) {
                data.status = "Completado";
                data.color = "success";
            }

            return data;
        },
        stakeholdersDataIsCompletedData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            const addressValid = this.repLegalAddressData();
            const stakeHoldersValid = this.stakeholdersDataIsCompleted();

            if((addressValid.status == "Completado" || addressValid.status == "Cargado") && stakeHoldersValid.status == "Completado") {
                data.status = "Completado";
                data.color = "success";
            } else {
                data.status = "Pendiente";
                data.color = "warning";
            }
            
            return data;
        },
        /**
         * Para el rep legal se solicita dirección y comprobante de domicilio.
         */
        repLegalAddressData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            // Dirección
            const hasAddress = this.onboardingStepData.client.user.business.personal.address.neighborhood_id != null;

            // Comprobante de domicilio
            const document = this.repLegalAddressProof;
            if(document != null && hasAddress) {
                if(document.is_verified == "verificado") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(document.is_verified == "rechazado") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(document.is_verified == "sin verificar") {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;
        },
        backFromStakeholders() {
            this.menuOption = 0;
        },
        async goContinue() {
            try {
                this.showLoading(true);
                const res = await axios.put(`/api/loan-onboarding/put/${this.base.id}/update-onboarding-documentation`);
                if (res.data == "success") {
                    await this.$emit("updated", 1);
                } else {
                    this.missingFieldsNotif();
                }
                this.showLoading(false);
            } catch (error) {
                this.showLoading(false);
                console.log(error);
                this.failedOperationNotif();
            }
        },
        hasError(val) {
            return this.errors.has(val);
        },  
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },

        // VALIDACIONES 
        getBankAccountData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.bankAccount) {
                const bankAccount = this.onboardingStepData.client.user.business.bank_accounts[0];
                if(bankAccount.verified == "verified") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(bankAccount.verified == "not_verified") {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(bankAccount.verified == "verified_requested") {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(bankAccount.verified == "rejected") {
                    data.status = "Rechazado";
                    data.color = "warning";
                }  else if(bankAccount.verified == "cep_requested") {
                    data.status = "Cargado";
                    data.color = "success";
                } 
            }

            return data;
        },
        getConstitutiveActData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.hasDocument) {
                if(this.document.is_verified == "verificado") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(this.document.is_verified == "rechazado") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(this.document.is_verified == "sin verificar") {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;
        
        },
        businessData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            let documentData = this.getConstitutiveActData();
            let bankAccountData = this.getBankAccountData();
            let addressDocument = this.addressDocument();
            // let financialStatementsData = this.getFinancialStatmentsData();

            if(documentData.status == "Pendiente" || bankAccountData.status == "Pendiente" || addressDocument.status == "Pendiente") {
                data.status = "Pendiente",
                data.color = "warning"
            } else if(documentData.status == "Rechazado" || bankAccountData.status == "Rechazado" || addressDocument.status == "Rechazado") {
                data.status = "Pendiente";
                data.color = "warning";
            } else if(bankAccountData.status == "Cargado" || documentData.status == "Cargado" || addressDocument.status == "Carga") {
                data.status = "Cargado";
                data.color = "success";
            } else if(documentData.status == "Completado" && bankAccountData.status == "Completado" && addressDocument.status == "Completado") {
                data.status = "Completado";
                data.color = "success";
            }

            return data;
        },
        async checkRepLegalData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            const repLegal = this.onboardingStepData.client.user.business.personal;

            let addressIsCompleted = this.repLegal.address.neighborhood_id != null;
            let doc = await this.getDocument(REP_LEGAL_ADDRESS_PROOF_ID, true);
            let docIsCompleted = doc.is_verified == "verificado" || doc.is_verified == "sin verificar";
            let identityIsCompleted = repLegal.identity_verifications.length > 0;

            if(addressIsCompleted && docIsCompleted && identityIsCompleted) {
                data.status = "Completado";
                data.color = "success";
            } else if(addressIsCompleted || docIsCompleted || identityIsCompleted) {
                data.status = "Cargado";
                data.color = "success";
            } else if(addressIsCompleted || docIsCompleted || identityIsCompleted) {
                data.status = "Rechazado";
                data.color = "warning";
            }

            return data;

        },
        async addressDocument() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };
            let doc = await this.getDocument(ADDRESS_DOCUMENT_REQUIRED_ID, true);
            if(doc != null) {
                let docStatus = doc.is_verified;
                if(docStatus == "rechazado") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(docStatus == "sin verificar") {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(docStatus == "verificado") {
                    data.status = "Completado";
                    data.color = "success";
                }
            }

            return data;
        },
        getFinancialStatmentsData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };
            if(this.isMounted) {
                let doc1 = this.financialStatements[0];
                let doc2 = this.financialStatements[1];
                let doc3 = this.financialStatements[2];
                if(doc1 != null && doc2 != null && doc3 != null) {
                    if(doc1.is_verified == "rechazado" || doc2.is_verified == "rechazado" || doc3.is_verified == "rechazado") {
                        data.status = "Rechazado";
                        data.color = "warning";
                    } else if(doc1.is_verified == "sin verificar" && doc2.is_verified == "sin verificar" && doc3.is_verified == "sin verificar") {
                        data.status = "Cargado";
                        data.color = "success";
                    } else if(doc1.is_verified == "verificado" && doc2.is_verified == "verificado" && doc3.is_verified == "verificado") {
                        data.status = "Completado";
                        data.color = "success";
                    }
                }
            }

            return data;
        },
        getLocationData() {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(this.hasLocationDocument) {
                if(this.projectDocument.is_verified == "verificado") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(this.projectDocument.is_verified == "rechazado") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(this.projectDocument.is_verified == "sin verificar") {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;
        
        },
        getIcon(icon) {
            const icons = {
                "04": "04_search_clock_icon.png"
            };

            return `https://cdn.redgirasol.com/suppliers/resources/icons/${icons[icon] || ""}`;
        },
    }
}
</script>
<style>

.border-bottom-green {
    border-bottom: 1px solid #A9E3A4;
    padding-bottom: 1rem;
}

</style>