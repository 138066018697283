<template>
    <div id="loan-offer" class="mt-base">
        <div v-if="is_in_general">
            <div class="vx-row mb-base">
                <div class="vx-col w-full">
                    <h2>Información de accionistas y representante legal.</h2>
                    <p>Ingresa la documentación pendiente de las siguientes personas.</p>
                    <vs-divider></vs-divider>
                </div>
            </div>
            <div class="vx-row mb-base m-1">
                <div class="vx-col sm:w-2/3 md:w-1/3">
                    <div class="flex">
                        <feather-icon
                                icon="UserIcon"
                                svgClasses="text-primary icon-size mr-4 mb-2"
                                class="cursor-pointer"
                            />
                        <div class="mt-2">
                            <h4>{{ business_personal_name }}</h4>
                            <p>Representante legal
                                <span v-if="repLegalIsGuarantee"> y Obligado solidario</span>
                            </p>
                        </div>
                    </div>
                    <div @click.stop="requestMatiPopUp(base, true)" class="flex justify-between mb-4 p-0 mt-4 border-bottom-green cursor-pointer">
                        <div class="mr-4">
                            <h5>INE</h5>
                            <p class="mb-2">Verificación de identidad</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="validateIdentity(base, true).color">{{ validateIdentity(base, true).status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                    <div @click.stop="changeMenuOption(base, 1, true)" class="flex justify-between mb-3 p-0 mt-4 border-bottom-green cursor-pointer">
                        <div class="mr-4">
                            <h5>Comprobante de domicilio</h5>
                            <p class="mb-2">No mayor a 3 meses, legible, sin cortes</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getAddressData(true).color">{{ getAddressData(true).status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                    <!-- INFORMACIÓN DEL OS  cdc-->
                    <div v-if="repLegalIsGuarantee" @click.stop="requestReminder(repLegalGuarantee, 2, getCDCStatus(repLegalGuarantee, true).status, true)" class="flex justify-between mb-3 p-0 mt-4 cursor-pointer">
                        <div class="mr-4">
                            <h5>Historial crediticio</h5>
                            <p class="">Revisaremos el historial crediticio de tu obligado solidario.</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getCDCStatus(repLegalGuarantee, true).color">{{ getCDCStatus(repLegalGuarantee, true).status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- ACCIONISTAS -->
            <div v-if="!isStakeholderRepLegal(st)" class="vx-row mb-base m-1" v-for="(st, index) in stakeholdersArray">
                <div class="vx-col sm:w-2/3 md:w-1/3">
                    <!-- {{ st }} -->
                    <div class="flex">
                        <feather-icon
                                icon="UserIcon"
                                svgClasses="text-primary icon-size mr-4 mb-2"
                                class="cursor-pointer"
                            />
                        <div class="mt-2">
                            <h4>{{ getStakeholerName(st) }}</h4>
                            <p v-if="st.is_guarantee">Accionista {{ getStakeholderPersonType(st) }} y Obligado solidario</p>
                            <p v-else>Accionista {{ getStakeholderPersonType(st) }}</p>
                        </div>
                    </div>
                    <div v-if="(st.stakeholder_pp_id != null || st.stakeholder_bp_id != null) && st.is_guarantee" @click.stop="!hasIdentityReminderRequested ? requestReminder(st, 1, getIdentityStatus(st).status) : ''" class="flex justify-between mb-4 p-0 mt-4 border-bottom-green cursor-pointer">
                        <div class="mr-4">
                            <h5>INE</h5>
                            <p class="mb-2">Verificación de identidad</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getIdentityStatus(st).color">{{ getIdentityStatus(st).status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                    <div v-if="st.is_guarantee" class="flex justify-between mb-3 p-0 mt-4 border-bottom-green cursor-pointer">
                        <div class="mr-4">
                            <h5>Comprobante de domicilio</h5>
                            <p class="mb-2">No mayor a 3 meses, legible, sin cortes</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getAddressData(false, st.id, st).color">{{ getAddressData(false, st.id, st).status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                    <!-- INFORMACIÓN DEL OS  cdc-->
                    <div v-if="(st.stakeholder_pp_id != null || st.stakeholder_bp_id != null) && st.is_guarantee" @click.stop="!hasCDCReminderRequested ? requestReminder(st, 2, getCDCStatus(st).status) : ''" class="flex justify-between mb-3 p-0 mt-4 cursor-pointer">
                        <div class="mr-4">
                            <h5>Historial crediticio</h5>
                            <p class="">Revisaremos el historial crediticio de tu obligado solidario.</p>
                        </div>
                        <div class="flex align-self-center">
                            <vs-chip class="mt-1" size="small" :color="getCDCStatus(st).color">{{ getCDCStatus(st).status }}</vs-chip>
                            <feather-icon
                                icon="ChevronRightIcon"
                                svgClasses="w-5 h-5 m-2 ml-4"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="vx-row">
                <div class="vx-col sm:w-1/2 md:w-1/12">
                    <vs-button size="sm" color="black" type="border" @click="goBAckToGeneral()">Atrás</vs-button>
                </div>
                <div class="vx-col sm:w-1/2 md:w-2/12">
                    <!-- <vs-button class="w-full" size="sm" color="black">Continuar</vs-button> -->
                </div>
            </div>
        </div>
        <div v-else-if="is_in_address">
            <ApplicantStakeholderAddress 
                v-if="isMounted && currentStakeholder != null" 
                @updated="updateStakeHolderAddress" 
                @on-back="clearMenuOption"
                :onboardingStepData="currentStakeholder" 
                :document="getDocumentObj(currentStakeholder.id, isRepLegal)"
                :isMoral="isMoral"
                :isRepLegal="isRepLegal"
                />
        </div>

        <mati-popup
            v-if="isMatiReady"
            :user-id-to-activate="matiData.userId" 
            :personal-profile-id="matiData.personalProfileId"
            @on-mati-process-started="onMatiProcessStarted" />
    </div>
</template>

<script>
import ApplicantStakeholderAddress from './ApplicantStakeholderAddress.vue';
import MatiPopup from "@components/mati/MatiPopup";
import userIdentityHelper from "@/helpers/userIdentityHelper";

const REP_LEGAL_ADDRESS_PROOF_ID = 31;

export default {
    name: "ApplicantStakeholdersDocuments",
    props: ["onboardingStepData"],
    mixins: [userIdentityHelper],
    components: {
        ApplicantStakeholderAddress,
        MatiPopup
    },
    data: () => ({
        menuOption: 0,
        currentStakeholder: null,
        isMoral: false,
        isMounted: false,
        currentStakeholders: [],
        isRepLegal: false,
        matiData: {
            userId: null,
            personalProfileId: null
        },
        stakeholderDocuments: [],
        document: null,
        stakeholdersArray: [],
        hasIdentityReminderRequested: false,
        hasCDCReminderRequested: false,
        repLegalGuarantee: null
    }),
    async created() {
        await this.getStakeholders();
        await this.getDocuments();
        await this.getDocument();
        await this.isRepLegalGuarantee();
    },
    computed: {
        apiStorageBasePrefix(){
            return this.ApiDomain + "/storagev3/documents/";
        },
        base() {
            return this.onboardingStepData;
        },
        finance() {
            return this.base.finance;
        },
        business() {
            return this.onboardingStepData.client.user.business;
        },
        business_personal() {
            return this.onboardingStepData.client.user.business.personal;
        },
        business_personal_name() {
            return this.business_personal.first_name + " " + (this.business_personal.second_name||"") + " " + this.business_personal.last_name_1 + " " + (this.business_personal.last_name_2||"");
        },
        is_in_general() {
            return this.menuOption == 0;
        },
        is_in_address() {
            return this.menuOption == 1;
        },
        isMatiReady() {
            return this.currentStakeholder != null;
        },
        guaranteesList() {
            return this.finance.guarantees_list;
        },
        repLegalIsGuarantee() {
            return this.repLegalGuarantee != null;
        }
    },
    methods: {
        isRepLegalGuarantee()
        {
            try {
                this.guaranteesList.forEach(guarantee => {
                    if(guarantee.guarantee_profile.personal_profile_id == this.business_personal.id) {
                        this.repLegalGuarantee = guarantee;
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        async requestReminder(stakeholder, flow, status, isRepLegal = false) {
            try {
                if(status == "Enviar recordatorio") {
                    this.showLoading(true);
                    const gid = isRepLegal ? stakeholder.guarantee_profile_id : stakeholder.guarantee_id;
                    await axios.post(`/api/guarantee/post/${gid}/send-reminder/${flow}`);
                    if(flow == 1) {
                        this.hasIdentityReminderRequested = true;
                    } else {
                        this.hasCDCReminderRequested = true;
                    }
                    this.showLoading(false);
                    this.showGuaranteeAlert(flow);
                }
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        showGuaranteeAlert(flow) {
            const text = flow == 1 ? "verifique su identidad" : "autorización para consultar su historial crediticio";
            this.$vs.dialog({
                color: 'success',
                title: 'Notificaremos a tu obligado.',
                text: `Enviamos un correo a tu Obligado Solidario en donde se le solicitará la ${text}.
                        \n Por favor comunícate con el/ella para que no se extrañe de este correo y nos ayude en tu proceso de solicitud de crédito.`,
                acceptText: "Continuar",
            });
        },
        isStakeholderRepLegal(tr) {
            const ppId = this.business_personal.id;

            return tr.stakeholder_pp_id == ppId;
        },
        setStakeholdersForAdmin() {
            this.stakeholdersArray = [];
            this.currentStakeholders.forEach(st => {
                st.is_guarantee = (this.getGuaranteeData(st.guarantee_data) != null);
                st.guarantee_id = this.getGuaranteeData(st.guarantee_data) != null ? this.getGuaranteeData(st.guarantee_data).guarantee_id : null
                this.stakeholdersArray.push(st);
            });
        },
        getGuaranteeData(stakeholder) {
            const data = stakeholder.find(x => x.loan_id == this.onboardingStepData.id);
            if(data != null) {
                return data;
            } else {
                return null;
            }
        },
        async updateStakeHolderAddress() {
            if(this.isRepLegal) {
                await this.$emit('updated', 1);
            }
            await this.getStakeholders();
            await this.getDocuments();
            await this.getDocument();
        },
        async getStakeholders() {
            this.showLoading(true);
            this.isMounted = false;
            this.currentStakeholders = [];
            const res = await axios.get(`/api/loan-onboarding/get/${this.business.id}/get-stakeholders`);
            if (res.status == 200) {
                this.onboardingStepData.client.user.business.stakeholders = [];
                this.onboardingStepData.client.user.business.stakeholders = res.data;
                this.currentStakeholders = res.data;
                this.setStakeholdersForAdmin();
            } else {
                this.failedOperationNotif(null, "No se pudo obtener la información de los accionistas.");
            }

            if(this.currentStakeholder != null) {
                const stakeholderId = this.currentStakeholder.id;
                this.currentStakeholder = this.currentStakeholders.find(stakeholder => stakeholder.id == stakeholderId);
            }

            if(this.isRepLegal) {
                this.currentStakeholder = this.base;
            }

            this.isMounted = true;
            this.showLoading(false);
        },
        getStakeholerName(stakeholder){
            // Es persona física
            if(stakeholder.stakeholder_pp_id != null) {
                return stakeholder.personal_profile.first_name + " " + (stakeholder.personal_profile.second_name||'') + " " + stakeholder.personal_profile.last_name_1 + " " + (stakeholder.personal_profile.last_name_2||'');
            } else {
                return stakeholder.business_profile.name;
            }
        },
        getStakeholderPersonType(stakeholder){
            // Es persona física
            if(stakeholder.stakeholder_pp_id != null) {
                return "Persona física";
            } else {
                return "Persona moral";
            }
        },
        getStakeholdersAddress(stakeholder){
            // Es persona física
            if(stakeholder.stakeholder_pp_id != null) {
                return stakeholder.personal_profile.address;
            } else {
                return stakeholder.business_profile.address;
            }
        },
        changeMenuOption(stakeholder, option, isRepLegal = false) {
            this.menuOption = option;
            this.currentStakeholder = stakeholder;
            if(stakeholder.stakeholder_pp_id != null || isRepLegal) {
                this.isMoral = false;
            } else {
                this.isMoral = true;
            }

            this.isRepLegal = isRepLegal;
            this.isMounted = true;
        },
        getProjectData() {

        },
        clearMenuOption() {
            this.menuOption = 0;
            this.currentStakeholder = null;
        },
        async requestMatiPopUp(profile, isRepLegal = false) {
            await this.clearMatiPoUpSettings();
            if(isRepLegal) {
                this.matiData.userId = profile.client.user.id;
            } else {
                if(profile.stakeholder_pp_id) {
                    this.matiData.personalProfileId = profile.personal_profile.id;
                } else {
                    this.matiData.personalProfileId = profile.personal.id;
                }
            }
            this.currentStakeholder = profile;
            this.openMatiPopup();
        },
        clearMatiPoUpSettings() {
            this.matiData = {
                userId: null,
                personalProfileId: null
            }
            this.currentStakeholder = null;
        },
        async getPersonalIdentityInfo(personalId) {
            this.status = await this.getPersonalIdentityStatus(personalId);
            this.identityIsCompleted = this.verificationIsCompleted(personalId);
        },
        async onMatiProcessStarted() {
              this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
              this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", false);
              this.processWasStarted = true;
          },
        async openMatiPopup() {
            this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
            this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", true);
        },
        onMatiProcessFinished() {
            window.location.reload();
        },
        validateAddress(stakeholder, isRepLegal = false) {
            let address = null;
            if (isRepLegal) {
                address = stakeholder.client.user.business.personal.address;
                return address.neighborhood_id != null && this.document != null;
            } else {
                if(stakeholder.stakeholder_pp_id) {
                    address = stakeholder.personal_profile.address;
                } else {
                    address = stakeholder.business_profile.address;
                }
                return address.neighborhood_id != null && this.getDocumentObj(stakeholder.id) != null;
            }

        },
        validateIdentity(stakeholder, isRepLegal = false) {
            let profile = null;
            if (isRepLegal) {
                profile = stakeholder.client.user.business.personal;
            } else {
                if(stakeholder.stakeholder_pp_id) {
                    profile = stakeholder.personal_profile;
                } else {
                    profile = stakeholder.business_profile.personal;
                }
            }
            const identityData = profile.identity_verifications;
            let data = this.getIdentityData(identityData);
            return data;
        },
        validateStakeHolderComplete(stakeholder, isRepLegal = false) {
            return this.validateAddress(stakeholder, isRepLegal) && this.validateIdentity(stakeholder, isRepLegal);
        },
        goBAckToGeneral() {
            this.$emit('on-back');
        },
        hasError(val) {
            return this.errors.has(val);
        },  
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        async getDocuments() {
            try {
                const response = await axios.get(`${this.apiStorageBasePrefix}get/stakeholders/document-data/${this.UserId}`);
                this.stakeholderDocuments = response.data;
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        getDocumentObj(stakeholderId, isRepLegal = false) {
            if(isRepLegal) {
                return this.document;
            }

            for (let index = 0; index < this.stakeholderDocuments.length; index++) {
                const element = this.stakeholderDocuments[index].storage_file;
                let aux = element.split('_');
                let i = aux[aux.length-1].split(".");
                if(i[0] == stakeholderId) {
                    return this.stakeholderDocuments[index];
                }
            }

            return null;
        },
        async getDocument() {
            try {
                const response = await axios.get(`${this.apiStorageBasePrefix}get/document-data/${REP_LEGAL_ADDRESS_PROOF_ID}/${this.UserId}`);
                if(response.data != null && response.data != "") {
                    this.document = response.data;
                }
            } catch (error) {
                this.showLoading(false);
                this.$vs.notify({
                    title: 'Error',
                    text: 'Ocurrió un error al obtener la información',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
        getIdentityData(identityArray) {
            let data = {
                status: "Pendiente",
                color: "warning"
            };

            if(identityArray.length > 0) {
                const identity = identityArray[identityArray.length - 1];
                if(identity.identity_status == 1) {
                    data.status = "Completado";
                    data.color = "success";
                } else if(identity.identity_status == 0) {
                    data.status = "Cargado";
                    data.color = "success";
                } else if(identity.identity_status == 2) {
                    data.status = "En revisión";
                    data.color = "success";
                } else if(identity.identity_status == 4) {
                    data.status = "Rechazada";
                    data.color = "warning";
                } else {
                    data.status = "En revisión";
                    data.color = "success";
                }
            }

            return data;
        },
        getAddressData(isRepLegal, stakeholderId = null, stakeholder = null) {
            let data = {
                status: "Pendiente",
                color: "warning"
            };


            const document = isRepLegal ? this.document : this.getDocumentObj(stakeholderId);
            let address = null;

            if(isRepLegal) {
                address = this.business_personal.address;
            } else {
                if(stakeholder.stakeholder_pp_id) {
                    address = stakeholder.personal_profile.address;
                } else {
                    address = stakeholder.business_profile.address;
                }
            }


            if(document != null && address.neighborhood_id != null) {
                if(document.is_verified == "verificado") {
                    data.status = "Completado";
                    data.color = "success";
                } else if(document.is_verified == "rechazado") {
                    data.status = "Rechazado";
                    data.color = "warning";
                } else if(document.is_verified == "sin verificar") {
                    data.status = "Cargado";
                    data.color = "success";
                }
            }

            return data;
        },
        getCDCStatus(stakeholder, isRepLegalGuarantee = false) {
            let data = {
                status: "Enviar recordatorio",
                color: "warning"
            };

            let profile = null;

            if(isRepLegalGuarantee) {
                profile = this.business_personal;
            } else {
                if(stakeholder.stakeholder_pp_id != null) {
                    profile = stakeholder.personal_profile;
                } else {
                    profile = stakeholder.business_profile.personal;
                }
            }

            
            if(profile.last_credit_request != null) {
                data.status = "Completado";
                data.color = "success";
            }

            return data;
        },
        getIdentityStatus(stakeholder) {
            let data = {
                status: "Enviar recordatorio",
                color: "warning"
            };
            let profile = null;
            if(stakeholder.stakeholder_pp_id != null) {
                profile = stakeholder.personal_profile;
            } else {
                profile = stakeholder.business_profile.personal;
            }

            const identity = profile.identity_verifications[0];
            if(identity != null) {
                if(identity.identity_status == 1) {
                    data.status = "Completado";
                    data.color = "success";
                } else if(identity.identity_status == 0 || identity.identity_status == null) {
                    data.status = "Enviar recordatorio";
                    data.color = "warning";
                } else if(identity.identity_status == 2 || identity.identity_status == 3) {
                    data.status = "En revisión";
                    data.color = "warning";
                } else if(identity.identity_status == 4) {
                    data.status = "Rechazada";
                    data.color = "danger";
                } else {
                    data.status = "En revisión";
                    data.color = "warning";
                }
            }

            return data;
        },
    }
}
</script>
<style scoped>

.border-bottom-green {
    border-bottom: 1px solid #A9E3A4;
    padding-bottom: 1rem;
}

.icon-size {
    width: 4rem;
    height: 4rem;
}

.align-self-center {
    align-self: center;
}

</style>