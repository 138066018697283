<template>
    <div id="loan-detail" class="same-h" >
        <div ref="topElement"></div>
        <div v-if="!showOffer" class="vx-row">
            <div class="vx-class w-full pr-4 pl-4">
                <vx-card class="mt-2 mb-2">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <h1 class="big-title-bg extrabold mb-2 text-5xl">{{ getTitle }}</h1>
                            <vs-progress class="" :percent="calculatePercentageSteps()" :height="10" color="primary"></vs-progress>
                            <!-- {{ currentStep }} -->
                        </div>

                        <div v-if="isMounted" class="vx-col w-full">
                            <ProjectFGLocationAddress v-if="isEcotechnologiesLoanAux && currentStep == null" @updated="saveLocation" :project="base" />
                            <ApplicantAddress v-if="showApplicantAddress" @updated="getProjectData" :onboardingStepData="base" :isMoral="isMoral"/>
                            <ApplicantOnboardingGeneralMainUser v-if="currentStep == 'pm_2_business_address_confirmation'" :onboardingStepData="base" @updated="getProjectData"/>
                            <ApplicantRepLegalAddress v-if="currentStep == 'pm_legal_rep_information'" :onboardingStepData="base" @updated="getProjectData"/>
                            <ApplicantRepLegalIds v-if="currentStep == 'pm_3_personal_profile_address'" :onboardingStepData="base" @updated="getProjectData"/>
                            <ApplicantPersonalProfile ref="applicantPersonalProfile" v-if="currentStep == 'pm_4_personal_profile_curp'" @updated="getProjectData" :onboardingStepData="base" :isMoral="isMoral"/>
                            <ApplicantBusinessData v-if="currentStep == 'pm_5_personal_profile_sic_check'" @updated="goToDocuments" :onboardingStepData="base" :isMoral="isMoral"/>
                            <ApplicantBusinessData v-if="currentStep == 'pm_7_business_stakeholders'" @updated="goToDocuments" :onboardingStepData="base" :isMoral="isMoral"/>
                            <ApplicantDocuments v-if="currentStep == 'pm_8_personal_guarantees_confirmation'" @updated="getProjectData" @saved="getProjectDataLite" :onboardingStepData="base" :isMoral="isMoral"/>
                            <ContractsPage v-if="currentStep == 'pm_9_documents_and_identity'" @updated="getProjectData" :onboardingStepData="base" :isMoral="true" />
                            <!-- <div v-else>
                                <div class="center mt-4 mt-base vx-row mb-base">
                                    <div class="vx-col w-full">
                                        <h2>Algo salió mal</h2>
                                        <p>Lo sentimos, no encontramos la luz en esta dirección.</p>
                                        <p>Nos puedes contactar a través del chat y un agente de Redgirasol te ayudará.</p>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </vx-card>
            </div>
        </div>
        <div v-else class="">
            <LoanOffer v-if="isMounted" @updated="getProjectData" :onboardingStepData="base" :isMoral="isMoral" />
        </div>
    </div>
</template>
    
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import LoanOffer from "../components/shared_components/LoanOffer.vue";

// ONBOARDIGN STEP COMPONENTS
import ApplicantAddress from "../components/business/ApplicantAddress.vue";
import ProjectFGLocationAddress from "../components/ProjectFGLocationAddress.vue";
import ApplicantOnboardingGeneralMainUser from "../../onboarding/components/business/ApplicantOnboardingGeneralMainUser.vue";
import ApplicantRepLegalAddress from "../components/business/ApplicantRepLegalAddress.vue";
import ApplicantRepLegalIds from "../components/business/ApplicantRepLegalIds.vue";
import ApplicantPersonalProfile from "../components/business/ApplicantPersonalProfile.vue";
import ApplicantBusinessData from "../components/business/ApplicantBusinessData.vue";
import ApplicantDocuments from "../components/business/ApplicantDocuments.vue";
import ContractsPage from "../components/shared_components/ContractsPage.vue";

const requiredObjects = [
    'amortizations',    
    'finance.guaranteesList.guaranteeProfile',
    'finance.guarantee',
    'finance.guarantee.guaranteeProfile',
    'finance.guarantee.guaranteeProfile.personal',
    'finance.guarantee.guaranteeProfile.personal.identityVerifications',
    'finance.guarantee.guaranteeProfile.personal.phone',
    'finance.guarantee.guaranteeProfile.personal.address',

    'finance.guarantee.guaranteeProfile.business.address',
    'finance.guarantee.guaranteeProfile.business.personal',
    'finance.guarantee.guaranteeProfile.business.personal.identityVerifications',
    'finance.guarantee.guaranteeProfile.business.personal.phone',
    'finance.guarantee.guaranteeProfile.business.personal.address',

    'finance.owner.guaranteeProfile',
    'finance.owner.guaranteeProfile.personal',
    'finance.owner.guaranteeProfile.business',

    'client.user.business.ownerBusinessProfile',
    'client.user.business.ownerBusinessProfile.address',
    'client.user.business.ownerBusinessProfile.creditProfile',
    'client.user.business.ownerBusinessProfile.personal',
    'client.user.business.ownerBusinessProfile.phone',
    'client.user.business.ownerPersonalProfile',
    'client.user.business.ownerPersonalProfile.address',
    'client.user.business.ownerPersonalProfile.creditProfile',
    'client.user.business.ownerPersonalProfile.phone',

    'client.kycProfile',

    'client.user.personal.address',
    'client.user.personal.creditProfile',
    'client.user.personal.identityVerifications',
    'client.user.personal',
    'client.user.personal.bankAccounts',
    'client.user.personal.phone',
    'client.user.personal.PPIncomeSources.phone',
    'client.user.personal.PPIncomeSources.incomeSourcesProofs',

    'client.user.business.address',
    'client.user.business.personal.address',
    'client.user.business.personal.creditProfile',
    'client.user.business.personal',
    'client.user.business.personal.identityVerifications',
    'client.user.business.personal.phone',
    'client.user.business',
    'client.user.business.bankAccounts',
    'client.user.business.phone',
    'client.user.business.stakeholders',
    'client.user.business.stakeholders.business_profile',
    'client.user.business.stakeholders.business_profile.personal',
    'client.user.business.stakeholders.business_profile.personal.address',
    'client.user.business.stakeholders.business_profile.personal.identityVerifications',
    'client.user.business.stakeholders.business_profile.personal.phone',
    'client.user.business.stakeholders.business_profile.address',
    'client.user.business.stakeholders.personal_profile',
    'client.user.business.stakeholders.personal_profile.address',

    'contracts',
    'supplier',
    'supplier.contacts',
    'projectFgLocations',
    'agent',
    'agent.user',
    'agent.phone',
];

export default {
    name: "LoanDetailPM",
    props: ['id'],
    data() {
        return {
            loading: false,
            isMounted: false,
            base: {},
            step: 1,
            isEcotechnologiesLoanAux: false,
        }
    },
    components: {
        FormWizard,
        TabContent,
        ApplicantAddress,
        ApplicantPersonalProfile,
        LoanOffer,
        ApplicantBusinessData,
        ApplicantDocuments,
        ContractsPage,
        ProjectFGLocationAddress,
        ApplicantOnboardingGeneralMainUser,
        ApplicantRepLegalAddress,
        ApplicantRepLegalIds
    },
    computed: {
        currentStep() {
            return this.base.loan_request_step;
        },
        isMoral() {
            return this.UserPersonType == 0 || this.UserPersonType == 3;
        },
        isEcotechnologiesLoan() {
            return this.base.finance.loan_type == 1 && this.technologySelected?.family_group?.location_required == 1;
        },
        technologySelected() {
            if(this.isMounted) {
                return this.base.fg_locations[0];
            } else {
                return {};
            }
        },
        finance() {
            return this.base.finance;
        },
        hasOffer() {
            if(this.isMounted) {
                return this.base.loan_proposal_timestamp != null && this.base.finance.credit_acceptance_date == null;
            } else {
                return false;
            }
        },
        loanOfferIsAccepted() {
            if(this.isMounted) {
                return this.finance.credit_acceptance_date != null;
            } else {
                return false;
            }
        },
        showOffer() {
            if(this.loanOfferIsAccepted) {
                return false;
            } else if(this.hasOffer) {
                return true;
            } else {
                return false;
            }
        },
        showApplicantAddress() {
            if (this.isEcotechnologiesLoanAux) {
                return this.currentStep == "pm_1_business_loan_kickoff";
            } else {
                return this.currentStep == null || this.currentStep == "pm_1_business_loan_kickoff";
            }
        },
        getTitle()
        {
            let title = "Solicitud de crédito";

            switch (this.finance.loan_type) {
                case 1:
                title = "Crédito Solar";
                break;
                case 6:
                title = "Crédito Automotriz";
                break;
                case 4:
                title = "Crédito PYME";
                break;
                case 5:
                title = "Crédito Personal";
                break;
            
                default:
                break;
            }

            return title;
        }
    },
    watch: {
        // "base.loan_request_step": function (val) {
        //     this.calculateWizardStep();
        // }
    },
    async beforeMount() {
        this.isMounted = false;
        await this.getProjectData();
        // this.isEcotechnologiesLoanAux = this.isEcotechnologiesLoan;
        this.isMounted = true;
        // this.calculateWizardStep();
    },
    async created() {
        // await this.calculateWizardStep();
    },
    methods: {
        async getProjectDataLite() {
            try {
                this.showLoading(true);
                let params = "with[]=" + requiredObjects.join("&with[]=");
                let response = await axios.get(`/api/v2/projects/${this.id}?${params}`);
                this.base = response.data;
                await this.loadSavedProjectFgLocations();
                this.showLoading(false);
                this.gottop();
            }
            catch (e) {
                console.log(e);
                if (e.response.status === 403) {
                this.$router.replace('/solicitante/inicio');
                }
            }
        },
        async formSubmitted(){
            this.$vs.dialog({
                color: "success",
                type: "confirm",
                title: "Pre-solicitud creada correctamente",
                text: "Tu proyecto ha sido creado, y está en revisión para ser pre-aprobado. Puedes completar la solicitud mientras tanto si así lo deseas.",
                acceptText: "Ver solicitud",
                cancelText: 'Finalizar',
                accept: this.goToProject,
                cancel: this.finish,
                close: this.finish,
            })
        },
        goToProject() {
            // this.$router.replace({
            //     name: "detalleProyecto",
            //     params: { id: this.created_id }
            // });
        },
        finish(){
            // this.$router.replace({
            //     name: "misProyectos"
            // });
        },
        calculateWizardStep() {
            const wiz = this.$refs['wizard'];
            wiz.activateAll();
            if(this.isEcotechnologiesLoanAux) {
                // console.log("eco");
                if(this.currentStep == "pm_1_business_loan_kickoff") {
                    wiz.changeTab(0, 1);
                } else if (this.currentStep == "pm_2_business_address_confirmation"
                || this.currentStep == "pm_3_personal_profile_address"
                || this.currentStep == "pm_4_personal_profile_curp"
                    ) {
                    wiz.changeTab(0, 2);
                } else if(this.currentStep == "pm_5_personal_profile_sic_check") {
                    wiz.changeTab(0, 3);
                } else if(this.currentStep == "pm_6_credit_acceptance") {
                    wiz.changeTab(0, 4);
                } else if(this.currentStep == "pm_7_business_stakeholders") {
                    wiz.changeTab(0, 4);
                } else if(this.currentStep == "pm_8_personal_guarantees_confirmation") {
                    wiz.changeTab(0, 5);
                } else if(this.currentStep == "pm_9_documents_and_identity") {
                    wiz.changeTab(0, 6);
                }

            } else {
                // console.log("no-eco");
                if (this.currentStep == "pm_2_business_address_confirmation"
                || this.currentStep == "pm_3_personal_profile_address"
                || this.currentStep == "pm_4_personal_profile_curp"
                    ) {
                    wiz.changeTab(0, 1);
                } else if(this.currentStep == "pm_5_personal_profile_sic_check") {
                    wiz.changeTab(0, 2);
                } else if(this.currentStep == "pm_6_credit_acceptance") {
                    wiz.changeTab(0, 4);
                } else if(this.currentStep == "pm_7_business_stakeholders") {
                    wiz.changeTab(0, 4);
                } else if(this.currentStep == "pm_8_personal_guarantees_confirmation") {
                    wiz.changeTab(0, 5);
                } else if(this.currentStep == "pm_9_documents_and_identity") {
                    wiz.changeTab(0, 6);
                }
            }
        },
        async getProjectData() {
            try {
                this.showLoading(true);
                // this.isMounted = false;
                let params = "with[]=" + requiredObjects.join("&with[]=");
                let response = await axios.get(`/api/v2/projects/${this.id}?${params}`);
                this.base = response.data;
                await this.loadSavedProjectFgLocations();
                // this.isMounted = true;
                this.showLoading(false);
                if(this.base.finance.loan_type == 1) {
                    const familyGroupId = this.base.project_fg_locations[0].family_group_id;
                    this.isEcotechnologiesLoanAux = this.base.finance.loan_type == 1 && familyGroupId == 1;
                } else {
                    this.isEcotechnologiesLoanAux = false;
                }
                // await this.calculateWizardStep();
                this.gottop();
            }
            catch (e) {
                console.log(e);
                if (e.response.status === 403) {
                    this.$router.replace('/solicitante/inicio');
                }
            }
        },
        async loadSavedProjectFgLocations(){
            try {
                // this.showLoading(true);
                const res = await axios.get(`/api/loan-onboarding/get/${this.base.id}/getProjectFgLocations`);
                this.base.fg_locations = res.data;
                // this.showLoading(false);
            }
            catch (e) {
                console.log(e);
            }
        },
        async validateStep() {
            try {
                return await new Promise(async (resolve, reject) => {
                    
                    if(await this.isLateOnboarding()) {
                        resolve(true);
                        return;
                    }
                    
                    this.showLoading(true);
                    const res = await axios.put(`/api/loan-onboarding/put/${this.ApplicantId}/update-onboarding-pm`, { 
                        project_id: this.base.id,
                    });
                    if (res.data == "success") {
                        await this.getProjectData();
                        resolve(true);
                    } else {
                        this.missingFieldsNotif();
                        reject(false);
                    }
                    this.showLoading(false);
                });
            } catch (error) {
                console.log(error);
                this.failedOperationNotif();
            }
        },
        async validatePersonal() {
            try {
                return await new Promise(async (resolve, reject) => {

                    if(await this.isLateOnboarding() ) {
                        this.showLoading(false);
                        resolve(true);
                        return;
                    }

                    if(this.currentStep == "pm_2_business_address_confirmation") {
                        let address = this.$refs.applicantPersonalProfile.sameAddress;
                        if(address == 1) { // Sï es la misma dirección se tiene que actualizar 
                            this.showLoading(true);
                            const res = await axios.put(`/api/loan-onboarding/put/${this.base.id}/update-personal-address`);
                            if(!res) {
                                this.missingFieldsNotif();
                                reject(false);
                                this.showLoading(false);
                            }
                            this.base.loan_request_step = "pm_3_personal_profile_address";
                            this.showLoading(false);
                        }
                    }

                    this.showLoading(true);
                    // Sí estamos actualizando el rfc y curp
                    if(this.currentStep == "pm_3_personal_profile_address") {
                        const res = await this.$refs.applicantPersonalProfile.savePersonalIds();
                        if(!res) {
                            this.missingFieldsNotif();
                            reject(false);
                        }
                        this.base.loan_request_step = "pm_4_personal_profile_curp";
                        this.showLoading(false);
                    } else {
                        const res = await axios.put(`/api/loan-onboarding/put/${this.ApplicantId}/update-onboarding-pm`, { 
                            project_id: this.base.id,
                        });

                        if(this.base.loan_request_step == "pm_5_personal_profile_sic_check") {
                            if (res.data == "success") {
                                resolve(true);
                            } else {
                                this.missingFieldsNotif();
                                reject(false);
                            }
                        } else {
                            // resolve(false);
                        }
                    }

                    this.showLoading(false);
                });
            } catch (error) {
                console.log(error);
                this.failedOperationNotif();
            }
        },
        customizeLoan() {
            // const wiz = this.$refs['wizard'];
            // wiz.changeTab(2,3);
        },
        async acceptLoan() {
            await this.getProjectData()
            // if(this.isEcotechnologiesLoan) {
            //     wiz.changeTab(4,5);
            // } else {
            //     wiz.changeTab(3,4);
            // }
        },
        async goToDocuments() {
            await this.getProjectData()
        },
        async saveLocation() {
            this.base.loan_request_step = "pm_1_business_loan_kickoff";
        },
        isLateOnboarding() {
            if(this.currentStep != null) {
                const a = this.currentStep.slice(3,5);
                const b = parseInt(a);
    
                return b > 4;
            } else {
                return false;
            }
        },
        getWizardFooterClass() {
            let classText = "wizard-card-footer-none";
            if(this.isLateOnboarding()) {
                classText = "wizard-card-footer-none";
            } else {
                classText = "wizard-card-footer-block";
            }

            if(this.currentStep == null) {
                classText = "wizard-card-footer-none";
            }

            return classText;
        },
        calculatePercentageSteps() {
            const steps = {
                "pm_1_business_loan_kickoff" : 10,
                'pm_2_business_address_confirmation' : 20,
                'pm_legal_rep_information' : 30,
                'pm_3_personal_profile_address' : 40,
                'pm_4_personal_profile_curp' : 50,
                'pm_5_personal_profile_sic_check' : 60,
                'pm_6_credit_acceptance' : 70,
                'pm_7_business_stakeholders' : 80,
                'pm_8_personal_guarantees_confirmation' : 90,
                'pm_9_documents_and_identity' : 95,
                'pm_10_contracts_sign' : 100
            }

            return steps[this.currentStep] || 10;
        },
        gottop() {
            try {
                this.$refs.topElement?.scrollIntoView({ behavior: 'smooth' });
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>

<style>
#wizard.wizard-card-footer-none .wizard-card-footer {
    display: none;
}
#wizard.wizard-card-footer-block .wizard-card-footer {
    display: block;
}

.wizard-header {
    padding-top: 0px !important;
}

</style>